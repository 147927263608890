export const INF_MAIN_LAYOUT_CONTAINER_ID = 'INF_MAIN_LAYOUT_CONTAINER_ID';
export const HIGHLIGHT_ITEM_ID = 'HIGHLIGHT_ITEM_ID';
export const CALL_PAGE_OPEN_CATEGORY_LIST_ID = 'CALL_PAGE_OPEN_CATEGORY_ID';
export const CALL_PAGE_TOP_OF_SUGGESTIONS_SECTION_ANCHOR_ID =
  'CALL_PAGE_TOP_OF_SUGGESTIONS_SECTION_ANCHOR_ID';
export const CALL_PAGE_SWITCHES_BAR_ID = 'CALL_PAGE_SWITCHES_BAR_ID';
export const CALL_PAGE_CATEGORY_TABS_ID = 'CALL_PAGE_CATEGORY_TABS_ID';
export const RECORDING_PLAYER_FOOTER = 'RECORDING_PLAYER_FOOTER';
export const CALL_PAGE_OPEN_CATEGORY_CLASS_NAME = 'CALL_PAGE_OPEN_CATEGORY_CLASS_NAME';
export const SUGGESTION_CONTAINER_ROOT = 'SUGGESTION_CONTAINER_ROOT';
export const DEFAULT_APPBAR_HEIGHT = 48;

export enum ResultType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}
